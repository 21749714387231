import { FC } from "react";
import style from "./Spinner.module.scss";

interface SpinnerProps {
  error: boolean;
}

export const Spinner: FC<SpinnerProps> = ({ error }) => {
  return error ? (
    <p className={style.error}>
      Sorry!
      <br />
      Something went wrong 😥
    </p>
  ) : (
    <i className={style.spinner}></i>
  );
};
