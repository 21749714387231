import { FC } from "react";
import classNames from "classnames";
import style from "./GenreCloud.module.scss";

interface GenreProps {
  label: string;
  onClick: () => void;
  selectedGenre?: string;
}

interface GenreCloudProps {
  onClick: (genre: string) => void;
  selectedGenre?: string;
}

const GenreListElement: FC<GenreProps> = ({
  label,
  onClick,
  selectedGenre,
}) => {
  return (
    <button
      className={classNames(
        style.genre,
        selectedGenre &&
          (selectedGenre === label ? style.selected : style.disabled)
      )}
      onClick={() => onClick()}
      type="button"
    >
      {label}
    </button>
  );
};

export const GenreCloud: FC<GenreCloudProps> = ({ onClick, selectedGenre }) => {
  const genres = Array.from(
    new Set<string>([
      "Adventure",
      "Action",
      "Animation",
      "Biography",
      "Comedy",
      "Crime",
      "Drama",
      "Family",
      "Fantasy",
      "Film Noir",
      "History",
      "Horror",
      "Music",
      "Musical",
      "Mystery",
      "Romance",
      "Sci-Fi",
      "Thriller",
      "War",
      "Western",
    ])
  );

  return (
    <div className={style.genreList}>
      {genres.sort().map((genre) => {
        return (
          <GenreListElement
            key={genre}
            label={genre}
            onClick={() => onClick(genre)}
            selectedGenre={selectedGenre}
          />
        );
      })}
    </div>
  );
};
