import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import style from "./About.module.scss";

interface AboutProps {
  onClose: () => void;
}

interface ParagraphProps {
  title: string;
  illustration: string;
  illustrationPlace: "left" | "right";
  children: JSX.Element | JSX.Element[];
}

interface Author {
  name: string;
  profession: string;
  linkedIn: string;
  gitHub: string;
}

const AuthorCard: FC<Author> = ({ name, profession, linkedIn, gitHub }) => {
  return (
    <div className={style.card}>
      <div className={style.bio}>
        <p className={style.name}>{name}</p>
        <span className={style.profession}>{profession}</span>
      </div>
      <div className={style.socialLinks}>
        <a
          className={classNames(style.socialLink, style.linkedIn)}
          href={linkedIn}
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a
          className={classNames(style.socialLink, style.gitHub)}
          href={gitHub}
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
      </div>
    </div>
  );
};

const Paragraph: FC<ParagraphProps> = ({
  title,
  illustration,
  illustrationPlace,
  children,
}) => {
  return (
    <div>
      <h3>{title}</h3>
      <span
        className={classNames(style.illustration, style[illustrationPlace])}
      >
        {illustration}
      </span>
      {children}
    </div>
  );
};

export const About: FC<AboutProps> = ({ onClose }) => {
  const authors = [
    {
      name: "Mark Bryginskii",
      profession: "Web Developer",
      linkedIn: "https://www.linkedin.com/in/markbryginskii/",
      gitHub: "https://github.com/MarkBryginskii",
    },
    {
      name: "Elizaveta Bakaeva",
      profession: "Data Scientist",
      linkedIn: "https://www.linkedin.com/in/elizaveta-bakaeva-data-analyst/",
      gitHub: "https://github.com/elibakaeva",
    },
  ];

  const handleEscClose = useCallback(
    (e: { key: string }) => {
      e.key === "Escape" && onClose();
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscClose);
    return () => document.removeEventListener("keydown", handleEscClose);
  }, [handleEscClose]);

  return (
    <div className={style.popup}>
      <div className={style.container}>
        <button className={style.closeButton} onClick={onClose} />
        <div className={style.content}>
          <Paragraph title="Idea" illustration="🎬" illustrationPlace="right">
            <p>
              &ensp;Imagine this: you stumble across a great movie. You enjoy
              the characters, the plot is non-trivial and overall atmosphere
              fits right to our mood. Naturally, you go to a movie database
              website to discover interesting facts and get inspired to watch
              similar movies.
              <br />
              &ensp;What a suprise!
              <br />
              It turns out that the majority of a movie database website do not
              share your excitement about the movie you fell in love with. It
              seems like nobody understands you, you are all alone in the world
              and any search for something special like this is going to be
              unfruitful. Fear no more! Movies4Night will carefully listen to
              your opinion and filter out some more pictures to fill the gap in
              your heart.
              <br />
              <br />
              &ensp;This is a personal (non commercial) project done for skills
              demonstration purposes. With it, we first wanted to meet our own
              needs of finding the right movie and extend our expertise to new
              realms.
            </p>
          </Paragraph>
          <Paragraph
            title="Project description"
            illustration="🎞"
            illustrationPlace="left"
          >
            <p>
              &ensp;We combine engaging UI and data science to create a
              recommender system.
              <br />
              &ensp;The application presents a selection of movies within a
              selected genre, which user rates. The user can flip each card,
              read a movie's synopsis, click on a link to IMDb, rate or skip.
              Recommender then uses complete user ratings and returns a set of
              potential movies.
              <br />
              <br />
              Recommender pipeline steps include:
              <br />
              <br />
              1. Mine plot variables (e.g. active/passive actors and their
              actions) with a text parser (developed with NLTK library)
              <br />
              2. Reduce data dimensionality using Principal Component Analysis
              (PCA)
              <br />
              3. Build clusters on the reduced data
              <br />
              &ensp;&ensp;a. Production clusters group movies based on their
              similarity in characteristics related to how a movie is made
              (runtime, year, production country, etc).
              <br />
              &ensp;&ensp;b. Genre clusters group movies by plot variables, i.e.
              relates to what is depicted in the movie.
              <br />
              4. Prototype new ratings based on the similarities between rated
              movies and potential liked movies(from the same genre and
              production clusters).
              <br />
              5. Order potential movies by their potential rating and return top
              15
            </p>
          </Paragraph>
          <Paragraph
            title="From authors"
            illustration="📽"
            illustrationPlace="right"
          >
            <p>
              &ensp;Hi! My name is Mark and I was responsible for the frontend
              and UI/UX part.
              <br />
              With this project, I want to enrich my developer portfolio and
              prepare for future challenges in my career. I seek to showcase my
              hard skills such as React, JS, CSS, working with API and side
              libs.
              <br />
              <br />
              &ensp;Cheers, my name is Elizaveta and I developed backend and
              data science part of the project.
              <br />I have extensive experience in analysis, machine learning,
              and data visualisation, in Python, and Tableau. I decided to step
              in the project to learn new frameworks, such as django and
              djangorest, and get a grip on recommender systems and natural
              language processing.
            </p>
          </Paragraph>
          <div className={style.cardsContainer}>
            {authors.map((author, index) => {
              const { name, profession, linkedIn, gitHub } = author;
              return (
                <AuthorCard
                  key={index}
                  name={name}
                  profession={profession}
                  linkedIn={linkedIn}
                  gitHub={gitHub}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
