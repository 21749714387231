import { FC, useRef, useEffect, useState, CSSProperties } from "react";
import classNames from "classnames";
import style from "./FilmList.module.scss";
import { ratedFilm, Step } from "./App";
import { StarRating } from "./StarRating";
import { ListControls } from "./ListControls";
import { film } from "../utils/convertFilms";
import { Spinner } from "./Spinner";

interface FilmListProps {
  currentFilmIndex: number;
  setCurrentFilmIndex: (newIndex: number) => void;
  filmList?: film[];
  ratedFilms?: ratedFilm[];
  setRatedFilms?: (ratedFilms: ratedFilm[]) => void;
  currentStep: Step;
  error: boolean;
}

interface FilmCardProps {
  film: film;
  index: number;
  currentFilmIndex: number;
}

interface FilmInfoProps {
  film: film;
  short?: boolean;
}

const FilmInfo: FC<FilmInfoProps> = ({ film, short }) => {
  const { title, year, plot, matchPercentage } = film;

  return (
    <div className={classNames(style.info, short && style.short)}>
      <h3 className={classNames(style.title, short && style.short)}>{title}</h3>
      {matchPercentage && (
        <span className={style.matchPercentage}>
          Match score: <span>{matchPercentage}%</span>
        </span>
      )}
      <span className={style.year}>{year}</span>
      <p className={classNames(style.description, short && style.short)}>
        {plot}
      </p>
    </div>
  );
};

const FilmCard: FC<FilmCardProps> = ({ film, index, currentFilmIndex }) => {
  const { title, coverUrl, imdbId } = film;

  const [transformOrigin, setTransformOrigin] = useState<CSSProperties>();
  const [isFullInfoShown, setIsFullInfoShown] = useState<boolean>(false);

  useEffect(() => {
    index === currentFilmIndex &&
      setTimeout(() => setTransformOrigin({ transformOrigin: "center" }), 450);
    return setTransformOrigin(undefined);
  }, [currentFilmIndex, index]);

  return (
    <li
      className={classNames(
        style.filmCard,
        index < currentFilmIndex - 1 && style.past,
        index === currentFilmIndex - 1 && style.previous,
        index === currentFilmIndex && style.current,
        index === currentFilmIndex + 1 && style.next,
        index === currentFilmIndex + 2 && style.prepared,
        index === currentFilmIndex + 3 && style.future
      )}
      onClick={() => setIsFullInfoShown(!isFullInfoShown)}
      style={index === currentFilmIndex ? transformOrigin : undefined}
    >
      <div
        className={classNames(
          style.cardContainer,
          isFullInfoShown && style.backSide
        )}
      >
        <div className={style.front}>
          <img className={style.cover} src={coverUrl} alt={title} />
          <FilmInfo film={film} short />
        </div>
        <div className={style.back}>
          <FilmInfo film={film} />
          <a
            className={style.linkButton}
            href={`https://www.imdb.com/title/tt${imdbId}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            Check on IMDb
          </a>
        </div>
      </div>
    </li>
  );
};

export const FilmList: FC<FilmListProps> = ({
  currentFilmIndex,
  setCurrentFilmIndex,
  filmList,
  ratedFilms,
  setRatedFilms,
  currentStep,
  error,
}) => {
  const filmListRef = useRef<HTMLUListElement>(null);

  const onSkip = () => {
    setCurrentFilmIndex(currentFilmIndex + 1);
  };

  const setFilmRating = (imdbId: string, userRating: number) => {
    if (ratedFilms && setRatedFilms) {
      setRatedFilms([
        ...ratedFilms,
        { imdbId: imdbId, userRating: userRating },
      ]);
      setCurrentFilmIndex(currentFilmIndex + 1);
    }
  };

  const onClickLeft = () => {
    filmList && setCurrentFilmIndex(Math.max(currentFilmIndex - 1, 0));
  };

  const onClickRight = () => {
    filmList &&
      setCurrentFilmIndex(Math.min(currentFilmIndex + 1, filmList.length - 1));
  };

  const setTilt = (event: MouseEvent) => {
    const rotateValue = 15;
    const { width, height, left, top } =
      filmListRef.current?.getBoundingClientRect() as DOMRect;
    const { clientX, clientY } = event;

    const cardCenterX = width / 2;
    const cardCenterY = height / 2;

    const mouseX = clientX - left;
    const mouseY = clientY - top;

    const farX = mouseX - cardCenterX;
    const farY = mouseY - cardCenterY;

    const transformValueX = (
      ((rotateValue / 2) * farY) /
      cardCenterY
    ).toFixed();
    const transformValueY = (-(rotateValue * farX) / cardCenterX).toFixed();

    filmListRef.current?.setAttribute(
      "style",
      `transform: rotateX(${transformValueX}deg) rotateY(${transformValueY}deg); -webkit-transform: rotateX(${transformValueX}deg) rotateY(${transformValueY}deg);`
    );
  };

  const setDefaultTilt = () => {
    filmListRef.current?.setAttribute(
      "style",
      "-webkit-transform: rotateX(0deg) rotateY(0deg); -webkit-transition: transform ease-in-out 0.2s;"
    );
  };

  useEffect(() => {
    const element = filmListRef.current;
    const isMobileDevice = navigator.maxTouchPoints > 0;

    if (element && !isMobileDevice) {
      element.addEventListener("mousemove", setTilt);
      element.addEventListener("mouseleave", setDefaultTilt);
      return () => {
        element.removeEventListener("mousemove", setTilt);
        element.addEventListener("mouseleave", setDefaultTilt);
      };
    }
  }, [filmList]);

  return filmList ? (
    <div className={style.container}>
      <div className={style.ambientContainer}>
        <ul className={style.filmList} ref={filmListRef}>
          {filmList
            .slice(Math.max(currentFilmIndex - 2, 0), currentFilmIndex + 4)
            .map((film, index) => {
              return (
                <FilmCard
                  key={index + Math.max(currentFilmIndex - 2, 0)}
                  index={index + Math.max(currentFilmIndex - 2, 0)}
                  film={film}
                  currentFilmIndex={currentFilmIndex}
                />
              );
            })}
        </ul>
        <img
          className={style.ambient}
          src={filmList[currentFilmIndex].coverUrl}
          alt="Ambient background"
        />
      </div>
      {currentStep === 2 && (
        <StarRating
          onSkip={() => onSkip()}
          onStarClick={(userRating: number) =>
            setFilmRating(filmList[currentFilmIndex].imdbId, userRating)
          }
          disabled={ratedFilms && ratedFilms.length === 10}
        />
      )}
      {currentStep === 3 && (
        <ListControls
          currentFilmIndex={currentFilmIndex}
          leftButtonDisabled={currentFilmIndex === 0}
          rightButtonDisabled={currentFilmIndex === filmList.length - 1}
          onClickLeft={() => onClickLeft()}
          onClickRight={() => onClickRight()}
        />
      )}
    </div>
  ) : (
    <Spinner error={error} />
  );
};
