import { FC, useState, Touch } from "react";
import classNames from "classnames";
import style from "./StarRating.module.scss";

interface StarRatingProps {
  onSkip: () => void;
  onStarClick: (userRating: number) => void;
  disabled?: boolean;
}

interface StarProps {
  starValue: number;
  hovered: boolean;
  onHover?: () => void;
  onClick?: () => void;
}

const Star: FC<StarProps> = ({ starValue, hovered, onHover, onClick }) => {
  return (
    <button
      data-star-value={starValue}
      className={classNames(style.star, hovered && style.hovered)}
      onPointerEnter={onHover}
      onMouseDown={onHover}
      onTouchEnd={onClick}
      onMouseUp={onClick}
      type="button"
    ></button>
  );
};

export const StarRating: FC<StarRatingProps> = ({
  onSkip,
  onStarClick,
  disabled,
}) => {
  const stars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [hoveredStar, setHoveredStar] = useState(0);

  const touchHighlight = (touch: Touch) => {
    const { clientX, clientY } = touch;

    const touchedElement = document.elementFromPoint(clientX, clientY);
    const isStar = touchedElement?.hasAttribute("data-star-value");

    if (isStar) {
      const touchedStar = touchedElement?.getAttribute("data-star-value");
      touchedStar && setHoveredStar(Number(touchedStar));
    }
  };

  return (
    <div className={classNames(style.container, disabled && style.disabled)}>
      <button
        className={style.skip}
        onClick={onSkip}
        type="button"
        title="Never watched"
      ></button>
      <div
        className={style.starList}
        onMouseLeave={() => setHoveredStar(0)}
        onTouchMove={(e) => touchHighlight(e.touches[0])}
      >
        {stars.map((star) => {
          return (
            <Star
              key={star}
              starValue={star}
              onHover={() => setHoveredStar(star)}
              onClick={() => {
                onStarClick(star);
                setHoveredStar(0);
              }}
              hovered={hoveredStar >= star}
            />
          );
        })}
      </div>
      <span className={style.rating}>{`${hoveredStar}/10`}</span>
    </div>
  );
};
