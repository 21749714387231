import { FC } from "react";
import classNames from "classnames";
import style from "./ProgressBar.module.scss";
import { ratedFilm, Step } from "./App";

interface ProgressBarProps {
  currentStep: Step;
  selectedGenre?: string;
  ratedFilms: ratedFilm[];
}

interface ProgressElementProps {
  step: Step;
  currentStep: Step;
  progressValue: number;
  requiredValue: number;
}

const ProgressElement: FC<ProgressElementProps> = ({
  step,
  currentStep,
  progressValue,
  requiredValue,
}) => {
  return (
    <div
      className={classNames(
        style.elementContainer,
        currentStep === step && style.active
      )}
    >
      {currentStep >= step && (
        <div
          className={style.progress}
          style={{
            width: `${Math.round((progressValue * 100) / requiredValue)}%`,
          }}
        ></div>
      )}
    </div>
  );
};

export const ProgressBar: FC<ProgressBarProps> = ({
  currentStep,
  selectedGenre,
  ratedFilms,
}) => {
  return (
    <div className={style.container}>
      <ProgressElement
        step={1}
        currentStep={currentStep}
        progressValue={selectedGenre ? 1 : 0}
        requiredValue={1}
      />
      <ProgressElement
        step={2}
        currentStep={currentStep}
        progressValue={ratedFilms.length > 0 ? ratedFilms.length : 0}
        requiredValue={10}
      />
      <ProgressElement
        step={3}
        currentStep={currentStep}
        progressValue={1}
        requiredValue={1}
      />
    </div>
  );
};
