import { FC, useState, useEffect, useCallback, useMemo } from "react";
import classNames from "classnames";
import style from "./App.module.scss";
import { GenreCloud } from "./GenreCloud";
import { ProgressBar } from "./ProgressBar";
import { FilmList } from "./FilmList";
import { About } from "./About";
import axios from "axios";
import { convertFilms, film } from "../utils/convertFilms";

export type Step = 1 | 2 | 3;

export interface ratedFilm {
  imdbId: string;
  userRating: number;
}

export const App: FC = () => {
  const [currentStep, setCurrentStep] = useState<Step>(1);
  const [isAboutOpened, setIsAboutOpened] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [selectedGenre, setSelectedGenre] = useState<string>();
  const [filmsForRate, setFilmsForRate] = useState<film[]>();
  const [ratedFilms, setRatedFilms] = useState<ratedFilm[]>([]);
  const [recommendedFilms, setRecommendedFilms] = useState<film[]>();
  const [currentFilmIndex, setCurrentFilmIndex] = useState<number>(0);

  const apiEndPoints = useMemo(
    () => ({
      baseUrl: "https://movies4night.com/api/",
      endPoint: {
        filmsForRate: "movies/",
        recommendedFilms: "recommended_movies/",
      },
    }),
    []
  );

  const stepTitle = {
    1: "Pick a genre",
    2: "Rate 10 movies",
    3: "15 movies you might like!",
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <GenreCloud
            onClick={(genre: string) => setSelectedGenre(genre)}
            selectedGenre={selectedGenre}
          />
        );
      case 2:
        return (
          <FilmList
            currentFilmIndex={currentFilmIndex}
            setCurrentFilmIndex={(newIndex: number) =>
              setCurrentFilmIndex(newIndex)
            }
            filmList={filmsForRate}
            ratedFilms={ratedFilms}
            setRatedFilms={setRatedFilms}
            currentStep={currentStep}
            error={error}
          />
        );
      case 3:
        return (
          <FilmList
            currentFilmIndex={currentFilmIndex}
            setCurrentFilmIndex={(newIndex: number) =>
              setCurrentFilmIndex(newIndex)
            }
            filmList={recommendedFilms}
            currentStep={currentStep}
            error={error}
          />
        );
    }
  };

  const reset = () => {
    setCurrentStep(1);
    setError(false);
    setSelectedGenre(undefined);
    setFilmsForRate(undefined);
    setRatedFilms([]);
    setRecommendedFilms(undefined);
    setCurrentFilmIndex(0);
  };

  const shuffle = (array: film[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const getFilmsForRate = useCallback(() => {
    axios
      .get(apiEndPoints.baseUrl + apiEndPoints.endPoint.filmsForRate, {
        params: {
          genre: selectedGenre?.toLowerCase().replace(/\s|\W/g, "_"),
        },
      })
      .then((res) => {
        const shuffleFilms = shuffle(convertFilms(res.data));
        const uniqueFilms = [...new Set(shuffleFilms)];
        setFilmsForRate(uniqueFilms);
      })
      .catch((err) => {
        console.log(err.message);
        setError(true);
      });
  }, [selectedGenre, apiEndPoints]);

  const getRecommendedFilms = useCallback(() => {
    axios
      .post(apiEndPoints.baseUrl + apiEndPoints.endPoint.recommendedFilms, {
        genre: selectedGenre?.toLowerCase().replace(/\s|\W/g, "_"),
        ratedFilms,
      })
      .then((res) => {
        setRecommendedFilms(convertFilms(res.data));
      })
      .catch((err) => {
        console.log(err.message);
        setError(true);
      });
  }, [selectedGenre, ratedFilms, apiEndPoints]);

  useEffect(() => {
    currentStep === 1 &&
      selectedGenre &&
      setTimeout(() => {
        setCurrentStep(2);
        getFilmsForRate();
      }, 1000);
    currentStep === 2 &&
      ratedFilms.length === 10 &&
      setTimeout(() => {
        setCurrentFilmIndex(0);
        setCurrentStep(3);
        getRecommendedFilms();
      }, 1000);
  }, [
    currentStep,
    selectedGenre,
    ratedFilms,
    getFilmsForRate,
    getRecommendedFilms,
  ]);

  return (
    <div className={classNames(style.app, isAboutOpened && style.noScroll)}>
      <header className={style.header}>
        <h1 className={style.title} onClick={() => reset()}>
          <span className={style.popcorn}>🍿</span> Movies4Night
        </h1>
        <button
          className={style.aboutButton}
          onClick={() => setIsAboutOpened(true)}
          type="button"
        >
          About
        </button>
      </header>
      <section className={style.content}>
        <h2 className={style.stepHeader}>{stepTitle[currentStep]}</h2>
        {getStepContent()}
        <ProgressBar
          currentStep={currentStep}
          selectedGenre={selectedGenre}
          ratedFilms={ratedFilms}
        />
      </section>
      {isAboutOpened && <About onClose={() => setIsAboutOpened(false)} />}
    </div>
  );
};
