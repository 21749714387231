interface ApiFilm {
  cover_url: string;
  title: string;
  imdb_id: string;
  rating: number;
  year: number;
  runtime_min: number;
  plot: string;
  genre_primary: string;
  genre_secondary: string;
  potential_rating?: number;
}

export interface film {
  imdbId: string;
  title: string;
  coverUrl: string;
  year: number;
  plot: string;
  matchPercentage?: number;
}

export const convertFilms = (apiResponse: ApiFilm[]): film[] => {
  const convertedFilms = apiResponse.map((element) => {
    const { imdb_id, title, cover_url, year, plot, potential_rating } = element;

    return {
      imdbId: imdb_id,
      title: title,
      coverUrl: cover_url.replace(".jpg", ".@._V1_QL75_UY580.jpg"),
      year: year,
      plot: plot,
      matchPercentage: potential_rating,
    };
  });

  return convertedFilms;
};
