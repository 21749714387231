import { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import style from "./ListControls.module.scss";

interface ListControlsProps {
  currentFilmIndex: number;
  leftButtonDisabled: boolean;
  rightButtonDisabled: boolean;
  onClickLeft: () => void;
  onClickRight: () => void;
}

export const ListControls: FC<ListControlsProps> = ({
  currentFilmIndex,
  leftButtonDisabled,
  rightButtonDisabled,
  onClickLeft,
  onClickRight,
}) => {
  const handleHotKeys = useCallback(
    (e: { key: string }) => {
      e.key === "ArrowLeft" && onClickLeft();
      e.key === "ArrowRight" && onClickRight();
    },
    [onClickLeft, onClickRight]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleHotKeys);
    return () => document.removeEventListener("keydown", handleHotKeys);
  }, [handleHotKeys]);

  return (
    <div className={style.container}>
      <button
        className={classNames(style.left, leftButtonDisabled && style.disabled)}
        onMouseUp={onClickLeft}
        title="Scroll left"
        type="button"
      ></button>
      <span className={style.orderNumber}>{`${currentFilmIndex + 1}/15`}</span>
      <button
        className={classNames(
          style.right,
          rightButtonDisabled && style.disabled
        )}
        onMouseUp={onClickRight}
        title="Scroll right"
        type="button"
      ></button>
    </div>
  );
};
